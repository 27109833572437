import ApiService from './ApiService'
import { USER_MANAGEMENT } from 'constants/api.constant'

export const fetchWithdrawHistory = async (param) =>
  ApiService.fetchData({
    url:
      USER_MANAGEMENT +
      '/mitras/withdraw-history?page=' +
      param.pageIndex +
      '&size=' +
      param.pageSize,
    method: 'get',
  })

export const createWithdraw = async (data) =>
  ApiService.fetchData({
    url: USER_MANAGEMENT + '/mitras/withdraw',
    method: 'post',
    data,
  })

export const acceptWithdraw = async (data) =>
  ApiService.fetchData({
    url: USER_MANAGEMENT + '/mitras/withdraw-approve',
    method: 'post',
    data,
  })

export const rejectWithdraw = async (data) =>
  ApiService.fetchData({
    url: USER_MANAGEMENT + '/mitras/withdraw-reject',
    method: 'post',
    data,
  })
