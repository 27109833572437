export const TOKEN_TYPE = 'Bearer '
export const REQUEST_HEADER_AUTH_KEY = 'Authorization'
export const VERSION = '/v1'

export const DASHBOARD_MANAGEMENT = '/DM'
export const USER_MANAGEMENT = '/UM'
export const ADDRESS_MANAGEMENT = '/AM'
export const SETTING_MANAGEMENT = '/SM'
export const CONTENT_MANAGEMENT = '/CTM'
export const CHARGING_MANAGEMENT = '/CGM'
export const TRANSACTION_MANAGEMENT = '/TM'
export const CHARGERS_COMMAND = '/CP'
export const ADDITIONAL = '/ADD'
export const VEHICLE_MANAGEMENT = '/VHM'
