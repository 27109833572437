const { createSlice } = require('@reduxjs/toolkit')

const stateSlice = createSlice({
  name: 'feedbackConfig/state',
  initialState: {
    showDeleteConfirmation: false,
    isShowDialog: false,
    dialogTitle: '',
  },
  reducers: {
    toggleDeleteConfirmation: (state, action) => {
      state.showDeleteConfirmation = action.payload
    },
    toggleDialog: (state, action) => {
      state.isShowDialog = action.payload
    },
    setDialogTitle: (state, action) => {
      state.dialogTitle = action.payload
    },
  },
})

export const actions = stateSlice.actions
export default stateSlice.reducer
