import { TRANSACTION_MANAGEMENT } from 'constants/api.constant'
import ApiService from './ApiService'

export const fetchCoffeeVoucherList = async (param) => {
  let URL = `${TRANSACTION_MANAGEMENT}/coffee-vouchers`
  if (param.tableSearch) {
    URL += `?search=${param.tableSearch}`
  }
  return ApiService.fetchData({
    url: URL,
    method: 'get',
  })
}
