import * as services from 'services/CoffeeVoucherService'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const getCoffeeVoucherListApi = createAsyncThunk(
  'coffeeVoucher/getCoffeeVoucherList',
  async (param) => {
    const response = await services.fetchCoffeeVoucherList(param)
    return response.data.data
  },
)

const dataSlice = createSlice({
  name: 'coffeeVoucher/data',
  initialState: {
    loading: true,
    // Table Metadata?
    tableData: {
      total: 0,
      pageIndex: 1,
      pageSize: 10,
      tableSearch: '',
    },
    coffeeVoucherList: [],
  },
  reducers: {
    setTableData: (state, action) => {
      state.tableData = action.payload
    },
    setTableSearch: (state, action) => {
      state.tableSearch = action.payload
    },
  },
  extraReducers: (builder) => {
    // GET listener
    builder
      .addCase(getCoffeeVoucherListApi.pending, (state) => {
        state.loading = true
      })
      .addCase(getCoffeeVoucherListApi.fulfilled, (state, action) => {
        state.loading = false
        state.coffeeVoucherList = action.payload
      })
      .addCase(getCoffeeVoucherListApi.rejected, (state) => {
        state.loading = false
      })
  },
})

export const { setTableData, setTableSearch } = dataSlice.actions
export const storeServices = {
  getCoffeeVoucherListApi,
}

export default dataSlice.reducer
