import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { fetchRFIDsApi } from 'services/RfidService'

const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: '',
  sort: {
    order: '',
    key: '',
  },
}

export const getRFIDsApi = createAsyncThunk('rfid/getRFIDs', async (data) => {
  const response = await fetchRFIDsApi(data?.tableSearch)
  return response.data
})

const dataSlice = createSlice({
  name: 'rfid/data',
  initialState: {
    loading: true,
    rfidList: [],
    /**
     * "id": "1235689013",
     * "userEmail": "rombar@novatech.id",
     * "expiredAt":"2023-12-28",
     * "balance": 100000
     */
    form: { id: '', userEmail: '', expiredAt: '' },
    // {
    //   "status":"Binded",
    //   "userEmail": "rombar@novatech.id",
    //   "expiredAt":"2023-12-28",
    //   "balance": 100000
    // }
    editFormID: null,
    editForm: { status: '', userEmail: '', expiredAt: '' },
    deleteID: null,
    tableData: initialTableData,
    tableSearch: '',
  },
  reducers: {
    setTableData: (state, action) => {
      state.tableData = action.payload
    },
    setTableSearch: (state, action) => {
      state.tableSearch = action.payload
    },
    setForm: (state, action) => {
      state.form = action.payload
    },
    setEditFormID: (state, action) => {
      state.editFormID = action.payload
    },
    setEditForm: (state, action) => {
      state.editForm = {
        status: action.payload.status,
        userEmail: action.payload.userEmail || '',
        expiredAt: action.payload.expiredAt,
      }
    },
    resetForm: (state) => {
      state.form = { id: '', userEmail: '', expiredAt: '' }
      state.editForm = { status: '', userEmail: '', expiredAt: '' }
      state.editFormID = null
    },
    setDeleteID: (state, action) => {
      state.deleteID = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRFIDsApi.fulfilled, (state, action) => {
        state.loading = false
        state.rfidList = action.payload.data.cards
      })
      .addCase(getRFIDsApi.pending, (state) => {
        state.loading = true
      })
      .addCase(getRFIDsApi.rejected, (state) => {
        state.loading = false
        state.rfidList = []
      })
  },
})

export const { setTableData, setTableSearch, setForm, resetForm } = dataSlice.actions
export const actions = dataSlice.actions
export const storeServices = {
  getRFIDsApi,
}
export default dataSlice.reducer
