import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchDistrictApi, fetchProvinceApi } from 'services/AddressService'

const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  tableSearch: '',
}

const initialFormDistrict = {
  name: '',
  provinceId: '',
}

export const getDistrictsApi = createAsyncThunk('district/getDistricts', async (param) => {
  const response = await fetchDistrictApi(param)
  return response.data
})

export const getProvincesApi = createAsyncThunk('province/getProvinces', async (param) => {
  const response = await fetchProvinceApi(param)
  return response.data
})

const dataSlice = createSlice({
  name: 'address/data',
  initialState: {
    loading: false,
    provinceList: [],
    loadingDistrict: false,
    districtList: [],
    formProvince: { name: '' },
    formDistrict: initialFormDistrict,
    tableDataProvince: initialTableData,
    tableDataDistrict: initialTableData,
    tableSearch: '',
  },
  reducers: {
    setTableDataProvince: (state, action) => {
      state.tableDataProvince = action.payload
    },
    setTableDataDistrict: (state, action) => {
      state.tableDataDistrict = action.payload
    },
    setForm: (state, action) => {
      state.formProvince = action.payload
      state.formDistrict = action.payload
    },
    resetForm: (state) => {
      state.formProvince = { name: '' }
      state.formDistrict = initialFormDistrict
    },
  },
  extraReducers: (buider) => {
    buider
      .addCase(getProvincesApi.fulfilled, (state, action) => {
        state.loading = false
        state.provinceList = action.payload.data.provinces
        state.tableDataProvince.total = action.payload.data.totalData
      })
      .addCase(getProvincesApi.pending, (state) => {
        state.loading = true
      })
      .addCase(getProvincesApi.rejected, (state) => {
        state.loading = false
        state.provinceList = []
      })

    buider
      .addCase(getDistrictsApi.pending, (state) => {
        state.loading = true
      })
      .addCase(getDistrictsApi.fulfilled, (state, action) => {
        state.loading = false
        state.districtList = action.payload.data.districts
        state.tableDataDistrict.total = action.payload.data.totalData
      })

      .addCase(getDistrictsApi.rejected, (state) => {
        state.loading = false
        state.districtList = []
      })
  },
})

export const { setTableDataProvince, setTableDataDistrict, setForm, resetForm } = dataSlice.actions

export default dataSlice.reducer
