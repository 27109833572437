import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchAllNewsApi, fetchNewsApi } from 'services/NewsService'

const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  tableSearch: '',
  sort: {
    order: '',
    key: '',
  },
}

const initialForm = {
  id: '',
  name: '',
  imageId: '',
  content: '',
  categoryId: '',
}

export const getNewsApi = createAsyncThunk('news/getNewsApi', async (param) => {
  const response = await fetchAllNewsApi(param)
  return response.data
})

export const getNewsByIdApi = createAsyncThunk('news/getNewsByIdApi', async (id) => {
  const response = await fetchNewsApi(id)
  return response.data
})

const dataSlice = createSlice({
  name: 'news/data',
  initialState: {
    loading: true,
    newsList: [],
    form: initialForm,
    tableData: initialTableData,
    tableSearch: '',
  },
  reducers: {
    setTableData: (state, action) => {
      state.tableData = action.payload
    },
    setTableSearch: (state, action) => {
      state.tableSearch = action.payload
    },
    setForm: (state, action) => {
      state.form = action.payload
    },
    resetForm: (state) => {
      state.form = initialForm
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNewsApi.pending, (state) => {
        state.loading = true
      })
      .addCase(getNewsApi.fulfilled, (state, action) => {
        state.loading = false
        state.newsList = action.payload.data
      })
      .addCase(getNewsApi.rejected, (state) => {
        state.loading = false
        state.newsList = []
      })

    builder
      .addCase(getNewsByIdApi.pending, (state) => {
        state.loading = true
      })
      .addCase(getNewsByIdApi.fulfilled, (state, action) => {
        state.loading = false
        state.form = action.payload.data
      })
      .addCase(getNewsByIdApi.rejected, (state) => {
        state.loading = false
        state.form = initialForm
      })
  },
})

export const { setTableData, setTableSearch, setForm, resetForm, setLoading } = dataSlice.actions

export default dataSlice.reducer
