import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  fetchChargerApi,
  fetchChargerByIdApi,
  fetchChargerByStationApi,
} from 'services/ChargerService'

const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: '',
  sort: {
    order: '',
    key: '',
  },
}

const initialForm = {
  userId: '',
  stationId: '',
  chargerBoxId: '',
  name: '',
  url: '',
  power: '',
  // timeout: 0,
  // triger: 0,
  status: 'Active',
}

export const getChargerApi = createAsyncThunk('charger/getCharger', async () => {
  const response = await fetchChargerApi()
  return response.data
})

export const getChargerByIdApi = createAsyncThunk('charger/getChargerById', async (id) => {
  const response = await fetchChargerByIdApi(id)
  return response.data
})

export const getChargerByStationIdApi = createAsyncThunk(
  'charger/getChargerByStationId',
  async (id) => {
    const response = await fetchChargerByStationApi(id)
    return response.data
  },
)

const dataSlice = createSlice({
  name: 'charger/data',
  initialState: {
    loading: true,
    chargerList: [],
    form: initialForm,
    tableData: initialTableData,
    tableLog: initialTableData,
    tableSearch: '',
  },
  reducers: {
    setTableData: (state, action) => {
      state.tableData = action.payload
    },
    setTableLog: (state, action) => {
      state.tableLog = action.payload
    },
    setTableSearch: (state, action) => {
      state.tableSearch = action.payload
    },
    setForm: (state, action) => {
      state.form = action.payload
    },
    resetForm: (state) => {
      state.form = initialForm
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChargerApi.fulfilled, (state, action) => {
        state.loading = false
        state.chargerList = action.payload.data
      })
      .addCase(getChargerApi.pending, (state) => {
        state.loading = true
      })
      .addCase(getChargerApi.rejected, (state) => {
        state.loading = false
        state.chargerList = []
      })

    builder
      .addCase(getChargerByIdApi.fulfilled, (state, action) => {
        state.loading = false
        state.form = action.payload.data
      })
      .addCase(getChargerByIdApi.pending, (state) => {
        state.loading = true
      })
      .addCase(getChargerByIdApi.rejected, (state) => {
        state.loading = false
        state.form = initialForm
      })

    builder
      .addCase(getChargerByStationIdApi.fulfilled, (state, action) => {
        state.loading = false
        state.chargerList = action.payload.data
      })
      .addCase(getChargerByStationIdApi.pending, (state) => {
        state.loading = true
      })
      .addCase(getChargerByStationIdApi.rejected, (state) => {
        state.loading = false
        state.chargerList = []
      })
  },
})

export const { setTableData, setTableLog, setTableSearch, setForm, resetForm } = dataSlice.actions

export default dataSlice.reducer
