import ApiService from './ApiService'
import { CONTENT_MANAGEMENT } from 'constants/api.constant'

export const fetchLegalDocsApi = async (type = '') =>
  ApiService.fetchData({
    url: CONTENT_MANAGEMENT + '/legal-documents/' + type,
    method: 'get',
  })

export const createLegalDocApi = async (data) =>
  ApiService.fetchData({
    url: CONTENT_MANAGEMENT + '/legal-documents',
    method: 'post',
    data,
  })

export const updateLegalDocApi = async (type, data) =>
  ApiService.fetchData({
    url: CONTENT_MANAGEMENT + '/legal-documents/' + type,
    method: 'patch',
    data,
  })
