import ApiService from './ApiService'
import { USER_MANAGEMENT } from 'constants/api.constant'

export const fetchRolesApi = async () =>
  ApiService.fetchData({
    url: USER_MANAGEMENT + '/roles',
    method: 'get',
  })

export const createRoleApi = async (data) =>
  ApiService.fetchData({
    url: USER_MANAGEMENT + '/roles',
    method: 'post',
    data,
  })

export const updateRoleApi = async (data, id) =>
  ApiService.fetchData({
    url: USER_MANAGEMENT + '/roles/' + id,
    method: 'patch',
    data,
  })

export const deleteRoleApi = async (id) =>
  ApiService.fetchData({
    url: USER_MANAGEMENT + '/roles/' + id,
    method: 'delete',
  })
