import { createSlice } from '@reduxjs/toolkit'

const stateSlice = createSlice({
  name: 'address/state',
  initialState: {
    deleteConfirmation: false,
    dialog: false,
    titleDialog: '',
    descriptionDialog: '',
    actionId: '',
  },

  reducers: {
    setTitleDialog: (state, action) => {
      state.titleDialog = action.payload
    },
    setDescriptionDialog: (state, action) => {
      state.descriptionDialog = action.payload
    },
    toggleDeleteConfirmation: (state, action) => {
      state.deleteConfirmation = action.payload
    },
    toggleDialog: (state, action) => {
      state.dialog = action.payload
    },
    setActionId: (state, action) => {
      state.actionId = action.payload
    },
  },
})

export const {
  toggleDeleteConfirmation,
  toggleDialog,
  setTitleDialog,
  setDescriptionDialog,
  setActionId,
} = stateSlice.actions

export default stateSlice.reducer
