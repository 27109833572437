import ApiService from './ApiService'
import { ADDRESS_MANAGEMENT } from 'constants/api.constant'

export const fetchProvinceApi = async (param) =>
  ApiService.fetchData({
    url:
      ADDRESS_MANAGEMENT +
      '/provinces?page=' +
      param.pageIndex +
      '&size=' +
      param.pageSize +
      '&search=' +
      param.tableSearch,
    method: 'get',
  })

export const createProvinceApi = async (data) =>
  ApiService.fetchData({
    url: ADDRESS_MANAGEMENT + '/provinces',
    method: 'post',
    data,
  })

export const updateProvinceApi = async (data, id) =>
  ApiService.fetchData({
    url: ADDRESS_MANAGEMENT + '/provinces/' + id,
    method: 'patch',
    data,
  })

export const deleteProvinceApi = async (id) =>
  ApiService.fetchData({
    url: ADDRESS_MANAGEMENT + '/provinces/' + id,
    method: 'delete',
  })

export const fetchDistrictApi = async (param) =>
  ApiService.fetchData({
    url:
      ADDRESS_MANAGEMENT +
      '/districts?page=' +
      param.pageIndex +
      '&size=' +
      param.pageSize +
      '&search=' +
      param.tableSearch,
    method: 'get',
  })

export const createDistrictApi = async (data) =>
  ApiService.fetchData({
    url: ADDRESS_MANAGEMENT + '/districts',
    method: 'post',
    data,
  })

export const updateDistrictApi = async (data, id) =>
  ApiService.fetchData({
    url: ADDRESS_MANAGEMENT + '/districts/' + id,
    method: 'patch',
    data,
  })

export const deleteDistrictApi = async (id) =>
  ApiService.fetchData({
    url: ADDRESS_MANAGEMENT + '/districts/' + id,
    method: 'delete',
  })
