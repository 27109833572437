import { createSlice } from '@reduxjs/toolkit'

const initialForm = { id: '', month: new Date().getMonth() + 1, year: new Date().getFullYear() }

const stateSlice = createSlice({
  name: 'rfidInvoice/state',
  initialState: {
    deleteConfirmation: false,
    actionId: '',
    dialog: false,
    isEdit: false,
    form: initialForm,
  },

  reducers: {
    toggleDeleteConfirmation: (state, action) => {
      state.deleteConfirmation = action.payload
    },
    setDialogInvoice: (state, action) => {
      state.dialog = action.payload.dialog
      state.isEdit = action.payload.isEdit
      state.form = action.payload?.form ? action.payload.form : initialForm
    },
    setActionId: (state, action) => {
      state.actionId = action.payload
    },
  },
})

export const { toggleDeleteConfirmation, setActionId, setDialogInvoice } = stateSlice.actions

export default stateSlice.reducer
