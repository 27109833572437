import ApiService from './ApiService'
import { TRANSACTION_MANAGEMENT } from 'constants/api.constant'

export const fetchSummaryTransactionApi = async (param) =>
  ApiService.fetchData({
    url: TRANSACTION_MANAGEMENT + '/transactions/summary',
    method: 'get',
    params: {
      groupBy: param.station,
    },
  })

export const fetchTransactionApi = async (param) =>
  ApiService.fetchData({
    url: TRANSACTION_MANAGEMENT + '/transactions',
    method: 'get',
    params: {
      page: param.pageIndex,
      size: param.pageSize,
      search: param.tableSearch,
      filter: param.mitra,
      dateStart: param.dateStart,
      dateEnd: param.dateEnd,
      stationId: param.stationId,
    },
  })

export const fetchTransactionByUserApi = async (id = '') =>
  ApiService.fetchData({
    url: TRANSACTION_MANAGEMENT + '/transactions/by-user-id/' + id,
    method: 'get',
  })

export const fetchExportTransactionApi = async (start, end, stationId) => {
  var url = TRANSACTION_MANAGEMENT + '/transactions/date?dateStart=' + start + '&dateEnd=' + end + '&status=Success'
  if (stationId) {
    url += '&stationId=' + stationId;
  }
  return ApiService.fetchData({
    url,
    method: 'get',
  })
}

export const fetchWalletAmountApi = async (id) =>
  ApiService.fetchData({
    url: TRANSACTION_MANAGEMENT + '/wallets/by-user-id/' + id,
    method: 'get',
  })

export const fetchWalletHistoryApi = async (param) =>
  ApiService.fetchData({
    url: TRANSACTION_MANAGEMENT + '/walletHistorys/by-user-id/' + param.id,
    method: 'get',
    params: {
      page: param.pageIndex,
      size: param.pageSize,
    },
  })

export const fetchProviderApi = async () =>
  ApiService.fetchData({
    url: TRANSACTION_MANAGEMENT + '/providers',
    method: 'get',
  })

export const createProviderApi = async (data) =>
  ApiService.fetchData({
    url: TRANSACTION_MANAGEMENT + '/providers',
    method: 'post',
    data,
  })

export const updateProviderApi = async (id, data) =>
  ApiService.fetchData({
    url: TRANSACTION_MANAGEMENT + '/providers/' + id,
    method: 'patch',
    data,
  })

export const deleteProviderApi = async (id) =>
  ApiService.fetchData({
    url: TRANSACTION_MANAGEMENT + '/providers/' + id,
    method: 'delete',
  })

export const fetchGatewayApi = async () =>
  ApiService.fetchData({
    url: TRANSACTION_MANAGEMENT + '/payment-gateways',
    method: 'get',
  })

export const createGatewayApi = async (data) =>
  ApiService.fetchData({
    url: TRANSACTION_MANAGEMENT + '/payment-gateways',
    method: 'post',
    data,
  })

export const updateGatewayApi = async (id, data) =>
  ApiService.fetchData({
    url: TRANSACTION_MANAGEMENT + '/payment-gateways/' + id,
    method: 'patch',
    data,
  })

export const deleteGatewayApi = async (id) =>
  ApiService.fetchData({
    url: TRANSACTION_MANAGEMENT + '/payment-gateways/' + id,
    method: 'delete',
  })

export const stopTransactionApi = async (data) =>
  ApiService.fetchData({
    url: TRANSACTION_MANAGEMENT + '/transactions/finish-charge-admin',
    method: 'post',
    data,
  })

export const fetchReservationApi = async (param) =>
  ApiService.fetchData({
    url: TRANSACTION_MANAGEMENT + '/reservations/admin/list',
    method: 'get',
    params: {
      page: param.pageIndex,
      size: param.pageSize,
    },
  })

export const deleteReservationApi = async (id) =>
  ApiService.fetchData({
    url: TRANSACTION_MANAGEMENT + '/reservations/admin/' + id,
    method: 'delete',
  })
