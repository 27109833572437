import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchFeedbackReportApi } from 'services/FeedbackReportService'

const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  tableSearch: '',
}

const initialForm = {
  userId: '',
  feedBackId: '',
  comment: '',
  rating: '',
}

export const getFeedbackReportApi = createAsyncThunk(
  'feedbackReport/getFeedbackReport',
  async (param) => {
    const response = await fetchFeedbackReportApi(param)
    return response.data
  },
)

const dataSlice = createSlice({
  name: 'feedbackReport/data',
  initialState: {
    loading: true,
    feedbackList: [],
    form: initialForm,
    tableData: initialTableData,
  },
  reducers: {
    setTableData: (state, action) => {
      state.tableData = action.payload
    },
    setForm: (state, action) => {
      state.form = action.payload
    },
    resetForm: (state) => {
      state.form = initialForm
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFeedbackReportApi.pending, (state) => {
        state.loading = true
      })
      .addCase(getFeedbackReportApi.fulfilled, (state, action) => {
        state.loading = false
        state.feedbackList = action.payload.data.feedBackReports
        state.tableData.total = action.payload.data.totalData
      })
      .addCase(getFeedbackReportApi.rejected, (state) => {
        state.loading = false
        state.feedbackList = []
      })
  },
})

export const { setTableData, setForm, resetForm } = dataSlice.actions

export default dataSlice.reducer
