import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchGatewayApi, fetchProviderApi } from 'services/TransactionService'

export const getProvidersApi = createAsyncThunk('provider/getProvider', async () => {
  const response = await fetchProviderApi()
  return response.data
})

export const getGatewaysApi = createAsyncThunk('gateway/getGateway', async () => {
  const response = await fetchGatewayApi()
  return response.data
})

const dataSlice = createSlice({
  name: 'payment/data',
  initialState: {
    providerList: { loading: false, data: [] },
    gatewayList: { loading: false, data: [] },
    providerForm: { code: '', name: '' },
    gatewayForm: { code: '', name: '', fee: '', providerId: '', percentage: '' },
  },
  reducers: {
    setProviderForm: (state, action) => {
      state.providerForm = action.payload
    },
    setGatewayForm: (state, action) => {
      state.gatewayForm = action.payload
    },
    resetForm: (state) => {
      state.providerForm = { code: '', name: '' }
      state.gatewayForm = { code: '', name: '', fee: '', providerId: '', percentage: '' }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProvidersApi.pending, (state) => {
        state.providerList.loading = true
      })
      .addCase(getProvidersApi.fulfilled, (state, action) => {
        state.providerList.data = action.payload.data
        state.providerList.loading = false
      })
      .addCase(getProvidersApi.rejected, (state) => {
        state.providerList.loading = false
        state.providerList.data = []
      })
    builder
      .addCase(getGatewaysApi.pending, (state) => {
        state.gatewayList.loading = true
      })
      .addCase(getGatewaysApi.fulfilled, (state, action) => {
        state.gatewayList.data = action.payload.data
        state.gatewayList.loading = false
      })
      .addCase(getGatewaysApi.rejected, (state) => {
        state.gatewayList.loading = false
        state.gatewayList.data = []
      })
  },
})

export const { setProviderForm, setGatewayForm, resetForm } = dataSlice.actions

export default dataSlice.reducer
