import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchLegalDocsApi } from 'services/LegalService'

const initialForm = {
  type: '',
  content: '',
}

export const getLegalDocs = createAsyncThunk('legal/getLegalDocs', async () => {
  const response = await fetchLegalDocsApi()
  return response.data
})

export const getLegalDocsByType = createAsyncThunk('legal/getLegalDocsByType', async (type) => {
  const response = await fetchLegalDocsApi(type)
  return response.data
})

const dataSlice = createSlice({
  name: 'legal/data',
  initialState: {
    loading: true,
    docsList: [],
    form: initialForm,
  },
  reducers: {
    setForm: (state, action) => {
      state.form = action.payload
    },
    resetForm: (state) => {
      state.form = initialForm
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLegalDocs.pending, (state) => {
        state.loading = true
      })
      .addCase(getLegalDocs.fulfilled, (state, action) => {
        state.loading = false
        state.docsList = action.payload.data
      })
      .addCase(getLegalDocs.rejected, (state) => {
        state.loading = false
        state.docsList = []
      })

    builder
      .addCase(getLegalDocsByType.pending, (state) => {
        state.loading = true
      })
      .addCase(getLegalDocsByType.fulfilled, (state, action) => {
        state.loading = false
        state.form = action.payload.data
      })
      .addCase(getLegalDocsByType.rejected, (state) => {
        state.loading = false
        state.form = initialForm
      })
  },
})

export const { setForm, resetForm, setLoading } = dataSlice.actions

export default dataSlice.reducer
