import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchWithdrawHistory } from 'services/WithdrawService'

const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  // tableSearch: '',
}

const initialData = {
  loading: false,
  data: [],
}

export const getWithdrawHistory = createAsyncThunk('withdraw/history', async (param) => {
  const response = await fetchWithdrawHistory(param)
  return response.data
})

const dataSlice = createSlice({
  name: 'withdraw/data',
  initialState: {
    withdrawHistory: initialData,
    tableData: initialTableData,
  },
  reducers: {
    setTableData: (state, action) => {
      state.tableData = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWithdrawHistory.pending, (state) => {
        state.withdrawHistory.loading = true
      })
      .addCase(getWithdrawHistory.fulfilled, (state, action) => {
        state.withdrawHistory.data = action.payload.data.withdrawHistory
        state.tableData.total = action.payload.data.totalData
        state.withdrawHistory.loading = false
      })
      .addCase(getWithdrawHistory.rejected, (state) => {
        state.withdrawHistory.loading = false
        state.withdrawHistory.data = ''
      })
  },
})

export const { setTableData, setStartDate, setEndDate, detailTransaction } = dataSlice.actions

export default dataSlice.reducer
