import { createSlice } from '@reduxjs/toolkit'

const stateSlice = createSlice({
  name: 'feedback/state',
  initialState: {
    deleteConfirmation: false,
    actionId: '',
    dialogDetail: false,
    detailData: {},
  },

  reducers: {
    toggleDeleteConfirmation: (state, action) => {
      state.deleteConfirmation = action.payload
    },
    setActionId: (state, action) => {
      state.actionId = action.payload
    },
    setDialogDetailFeedback: (state, action = '') => {
      state.dialogDetail = action?.payload?.dialog || false
      state.detailData = action?.payload?.data || {}
    },
  },
})

export const { toggleDeleteConfirmation, setActionId, setDialogDetailFeedback } = stateSlice.actions

export default stateSlice.reducer
