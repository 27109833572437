import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchMenuApi, fetchSubMenuApi, fetchSystemApi } from 'services/settingService'

const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: '',
  sort: {
    order: '',
    key: '',
  },
}

const initialFormMenu = {
  key: '',
  path: '',
  sortNum: '',
  title: '',
  translateKey: '',
  icon: '',
  type: '',
  authority: [],
  systemId: '',
  menuId: '',
  subMenus: [],
  id: '',
}

export const getSystemsApi = createAsyncThunk('systems/getSystemsApi', async () => {
  const response = await fetchSystemApi()
  return response.data
})

export const getMenusApi = createAsyncThunk('menus/getMenusApi', async () => {
  const response = await fetchMenuApi()
  return response.data
})

export const getNavbarApi = createAsyncThunk('navbar/getNavbarApi', async () => {
  const response = await fetchMenuApi()
  return response.data
})

export const getSubmenuApi = createAsyncThunk('sub-menus/getSubmenuApi', async () => {
  const response = await fetchSubMenuApi()
  return response.data
})

const dataSlice = createSlice({
  name: 'setting/data',
  initialState: {
    loadingSystem: false,
    loadingMenus: false,
    loadingSubmenu: false,
    loadingNavbar: false,
    systemList: [],
    menuList: [],
    submenuList: [],
    formSystem: { name: '' },
    formMenu: initialFormMenu,
    tableData: initialTableData,
    tableSearch: '',
  },
  reducers: {
    setTableData: (state, action) => {
      state.tableData = action.payload
    },
    setTableSearch: (state, action) => {
      state.tableSearch = action.payload
    },
    setFormSystem: (state, action) => {
      state.formSystem = action.payload
    },
    resetFormSystem: (state) => {
      state.formSystem = { name: '' }
    },
    setFormMenu: (state, action) => {
      state.formMenu = action.payload
    },
    resetFormMenu: (state) => {
      state.formMenu = initialFormMenu
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSystemsApi.pending, (state) => {
        state.loadingSystem = true
      })
      .addCase(getSystemsApi.fulfilled, (state, action) => {
        state.loadingSystem = false
        state.systemList = action.payload.data
      })
      .addCase(getSystemsApi.rejected, (state) => {
        state.loadingSystem = false
        state.systemList = []
      })

    builder
      .addCase(getMenusApi.pending, (state) => {
        state.loadingMenus = true
      })
      .addCase(getMenusApi.fulfilled, (state, action) => {
        state.loadingMenus = false
        state.menuList = action.payload.data
      })
      .addCase(getMenusApi.rejected, (state) => {
        state.loadingMenus = false
        state.menuList = []
      })

    builder
      .addCase(getSubmenuApi.pending, (state) => {
        state.loadingSubmenu = true
      })
      .addCase(getSubmenuApi.fulfilled, (state, action) => {
        state.loadingSubmenu = false
        state.submenuList = action.payload.data
      })
      .addCase(getSubmenuApi.rejected, (state) => {
        state.loadingSubmenu = false
        state.submenuList = []
      })

    builder
      .addCase(getNavbarApi.pending, (state) => {
        state.loadingNavbar = true
      })
      .addCase(getNavbarApi.fulfilled, (state, action) => {
        state.loadingNavbar = false
        state.menuList = action.payload.data
      })
      .addCase(getNavbarApi.rejected, (state) => {
        state.loadingNavbar = false
        state.menuList = []
      })
  },
})

export const {
  setTableData,
  setTableSearch,
  setFormSystem,
  resetFormSystem,
  setFormMenu,
  resetFormMenu,
} = dataSlice.actions

export default dataSlice.reducer
