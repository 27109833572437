import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchChargerApi } from 'services/ChargerService'

export const getChargerApi = createAsyncThunk('charger/getChargerCommand', async (param) => {
  const response = await fetchChargerApi(param)
  return response.data
})

const dataSlice = createSlice({
  name: 'chargerCommand/data',
  initialState: {
    loading: true,
    chargerCommand: [],
    selectLoading: false,
    dataSelect: '',
  },
  reducers: {
    setDataSelect: (state, action) => {
      state.dataSelect = action.payload
    },
    setSelectLoading: (state, action) => {
      state.selectLoading = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChargerApi.fulfilled, (state, action) => {
        state.loading = false
        state.chargerCommand = action.payload.data.chargers
      })
      .addCase(getChargerApi.pending, (state) => {
        state.loading = true
      })
      .addCase(getChargerApi.rejected, (state) => {
        state.loading = false
        state.chargerCommand = []
      })
  },
})

export const { setDataSelect, setSelectLoading } = dataSlice.actions

export default dataSlice.reducer
