import ApiService from './ApiService'
import { VEHICLE_MANAGEMENT } from 'constants/api.constant'

export const fetchVehicleApi = async (param) => {
  let query = '?page=' + param.pageIndex + '&size=' + param.pageSize
  if (param.tableSearch) {
    query += '&search=' + param.tableSearch
  }
  return ApiService.fetchData({
    url: VEHICLE_MANAGEMENT + '/admin/vehicle' + query,
    method: 'get',
  })
}

export const fetchVehicleByIdApi = async (id) =>
  ApiService.fetchData({
    url: VEHICLE_MANAGEMENT + '/admin/vehicle/' + id,
    method: 'get',
  })

export const createVehicleApi = async (data) =>
  ApiService.fetchData({
    url: VEHICLE_MANAGEMENT + '/admin/vehicle',
    method: 'post',
    data,
  })

export const updateVehicleApi = async (data, id) =>
  ApiService.fetchData({
    url: VEHICLE_MANAGEMENT + '/admin/vehicle/' + id,
    method: 'patch',
    data,
  })

export const deleteVehicleApi = async (id) =>
  ApiService.fetchData({
    url: VEHICLE_MANAGEMENT + '/admin/vehicle/' + id,
    method: 'delete',
  })
