import { combineReducers } from '@reduxjs/toolkit'
import state from './stateSlice'
import data from './dataSlice.js'

const reducer = combineReducers({
  state,
  data,
})

export default reducer
