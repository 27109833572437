import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import {
  fetchSummaryTransactionApi,
  fetchTransactionApi,
  fetchTransactionByUserApi,
} from 'services/TransactionService'

const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  tableSearch: 0,
  mitra: '',
  stationId: '',
}

export const getSummaryTransaction = createAsyncThunk('transaction/summary', async (param) => {
  const resp = await fetchSummaryTransactionApi(param)
  return resp.data
})

export const getTransactionsApi = createAsyncThunk('transaction/getTransactions', async (param) => {
  const response = await fetchTransactionApi(param)
  return response.data
})

export const getTransactionByIdApi = createAsyncThunk(
  'transaction/getTransactionById',
  async (id) => {
    const response = await fetchTransactionApi(id)
    return response.data
  },
)

export const getTransactionByUserApi = createAsyncThunk(
  'transaction/getTransactionByUser',
  async (id) => {
    const response = await fetchTransactionByUserApi(id)
    return response.data
  },
)

const dataSlice = createSlice({
  name: 'transaction/data',
  initialState: {
    loading: false,
    transactionList: [],
    summaryList: [],
    transactionDetail: '',
    transactionExportData: [],
    tableData: initialTableData,
    startDate: dayjs().subtract(1, 'month').toDate(),
    endDate: new Date(),
  },
  reducers: {
    setTableData: (state, action) => {
      state.tableData = action.payload
    },
    setStartDate: (state, action) => {
      state.startDate = action.payload
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload
    },
    detailTransaction: (state, action) => {
      state.transactionDetail = action.payload
    },
    setResetDateFilter: (state) => {
      state.startDate = dayjs().subtract(1, 'month').toDate()
      state.endDate = new Date()
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSummaryTransaction.pending, (state) => {
        state.loading = true
      })
      .addCase(getSummaryTransaction.fulfilled, (state, action) => {
        state.loading = false
        state.summaryList = action.payload.result
        // console.log(action.payload, 'summary')
        // state.tableData.total = action.payload.data.totalData
      })
      .addCase(getSummaryTransaction.rejected, (state) => {
        state.loading = false
        state.summaryList = []
      })

    builder
      .addCase(getTransactionsApi.pending, (state) => {
        state.loading = true
      })
      .addCase(getTransactionsApi.fulfilled, (state, action) => {
        state.loading = false
        state.transactionList = action.payload.data.transactions
        state.tableData.total = action.payload.data.totalData
      })
      .addCase(getTransactionsApi.rejected, (state) => {
        state.loading = false
        state.transactionList = []
      })

    builder
      .addCase(getTransactionByIdApi.pending, (state) => {
        state.loading = true
      })
      .addCase(getTransactionByIdApi.fulfilled, (state, action) => {
        state.loading = false
        state.transactionDetail = action.payload.data
      })
      .addCase(getTransactionByIdApi.rejected, (state) => {
        state.loading = false
        state.transactionDetail = ''
      })

    builder
      .addCase(getTransactionByUserApi.pending, (state) => {
        state.loading = true
      })
      .addCase(getTransactionByUserApi.fulfilled, (state, action) => {
        state.loading = false
        state.transactionList = action.payload.data
      })
      .addCase(getTransactionByUserApi.rejected, (state) => {
        state.loading = false
        state.transactionList = []
      })
  },
})

export const { setTableData, setStartDate, setEndDate, detailTransaction, setResetDateFilter } =
  dataSlice.actions

export default dataSlice.reducer
