import { TRANSACTION_MANAGEMENT } from 'constants/api.constant'
import ApiService from './ApiService'
import { CONFIG } from 'configs/app.config'

/**
 * Postman: https://nocatechteam.postman.co/workspace/Samudera~f8b1992b-8628-4993-bef3-b48413d00961/request/26422740-d1063e23-29a7-4093-8098-e7f57e111ff4?ctx=documentation
 *
 * @param {Object} param
 * @param {String} param.dateStart
 * @param {String} param.dateEnd
 * @returns
 */
export const fetchCoffeeReconciliation = async (params) => {
  if (!params.dateStart || !params.dateEnd) {
    throw new Error('Date start and date end is required')
  }

  let URL = `${TRANSACTION_MANAGEMENT}/transactions/coffee-reconciliation`
  let queryParams = {
    dateStart: params.dateStart,
    dateEnd: params.dateEnd,
    page: params.pageIndex,
    size: params.pageSize,
  }
  if (params.tableSearch) {
    queryParams.search = params.tableSearch
  }
  return ApiService.fetchData({
    url: URL,
    method: 'get',
    params: queryParams,
  })
}

export const fetchInvoiceApi = async (params) => {
  let query = `?month=${params.month}&year=${params.year}`
  if (params.status) {
    query += `&status=${params.status}`
  }
  return ApiService.fetchData({
    url: TRANSACTION_MANAGEMENT + '/admin/rfid-cards/invoice' + query,
    method: 'get',
  })
}

export const createInvoiceApi = async (data) =>
  ApiService.fetchData({
    url: TRANSACTION_MANAGEMENT + '/admin/rfid-cards/invoice',
    method: 'post',
    data,
  })

export const updateInvoiceApi = async (id, data) =>
  ApiService.fetchData({
    url: TRANSACTION_MANAGEMENT + '/admin/rfid-cards/invoice/' + id,
    method: 'put',
    data,
  })

export const deleteInvoiceApi = async (id) =>
  ApiService.fetchData({
    url: TRANSACTION_MANAGEMENT + '/admin/rfid-cards/invoice/' + id,
    method: 'delete',
  })

export const sendInvoiceApi = async (id) =>
  ApiService.fetchData({
    url: TRANSACTION_MANAGEMENT + '/admin/rfid-cards/invoice/' + id + '/send',
    method: 'post',
  })

export const downloadInvoiceApi = async (id, token) => {
  return fetch(
    CONFIG.REACT_APP_BASE_URL + '/v1/TM/admin/rfid-cards/invoice/' + id + '/download',
    {
      headers: {
        // Accept: 'application/pdf',
        // 'Access-Control-Allow-Headers': 'Content-Disposition',
        // 'Access-Control-Expose-Headers': 'Content-Disposition',
        Authorization: 'Bearer ' + token,
      },
    },
  )
}
