import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchRolesApi } from 'services/RoleService'

const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: '',
  sort: {
    order: '',
    key: '',
  },
}

export const getRolesApi = createAsyncThunk('roles/getRoles', async () => {
  const response = await fetchRolesApi()
  return response.data
})

const dataSlice = createSlice({
  name: 'roles/data',
  initialState: {
    loading: true,
    rolesList: [],
    form: { name: '', description: '' },
    tableData: initialTableData,
    tableSearch: '',
  },
  reducers: {
    setTableData: (state, action) => {
      state.tableData = action.payload
    },
    setTableSearch: (state, action) => {
      state.tableSearch = action.payload
    },
    setForm: (state, action) => {
      state.form = action.payload
    },
    resetForm: (state) => {
      state.form = { name: '', description: '' }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRolesApi.fulfilled, (state, action) => {
        state.loading = false
        state.rolesList = action.payload.data
      })
      .addCase(getRolesApi.pending, (state) => {
        state.loading = true
      })
      .addCase(getRolesApi.rejected, (state) => {
        state.loading = false
        state.rolesList = []
      })
  },
})

export const { setTableData, setTableSearch, setForm, resetForm } = dataSlice.actions

export default dataSlice.reducer
