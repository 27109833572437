import { getRFIDStatusApi } from 'services/RfidService'
const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit')

const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
}

export const getRFIDStatus = createAsyncThunk('rfid-status', async (params) => {
  const res = await getRFIDStatusApi(params)
  return res.data.data
})

const dataSlice = createSlice({
  name: 'rfidStatus/data',
  initialState: {
    statusList: [],
    loading: false,
    tableData: initialTableData,
  },
  reducers: {
    setTableDataStatus: (state, action) => {
      state.tableData = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRFIDStatus.pending, (state) => {
        state.loading = true
      })
      .addCase(getRFIDStatus.fulfilled, (state, action) => {
        state.loading = false
        state.statusList = action.payload.orders
        state.tableData.total = action.payload.totalData
      })
      .addCase(getRFIDStatus.rejected, (state) => {
        state.loading = false
        state.statusList = []
      })
  },
})

export const { setTableDataStatus } = dataSlice.actions
export default dataSlice.reducer
