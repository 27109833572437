import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchPromoApi } from 'services/PromotionService'

const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: '',
  sort: {
    order: '',
    key: '',
  },
}

const initialForm = {
  id: '',
  name: '',
  content: '',
  code: '',
  discount: '',
  amountMax: '',
  amountMin: '',
  discountType: '',
  expiredAt: '',
}

export const getPromoApi = createAsyncThunk('promo/getPromoApi', async (param) => {
  const response = await fetchPromoApi(param)
  return response.data
})

export const getPromoByIdApi = createAsyncThunk('promo/getPromoByIdApi', async (id) => {
  const response = await fetchPromoApi({ id })
  return response.data
})

const dataSlice = createSlice({
  name: 'promo/data',
  initialState: {
    loading: true,
    promoList: [],
    form: initialForm,
    tableData: initialTableData,
    tableSearch: '',
  },
  reducers: {
    setTableData: (state, action) => {
      state.tableData = action.payload
    },
    setTableSearch: (state, action) => {
      state.tableSearch = action.payload
    },
    setForm: (state, action) => {
      state.form = action.payload
    },
    resetForm: (state) => {
      state.form = initialForm
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPromoApi.pending, (state) => {
        state.loading = true
      })
      .addCase(getPromoApi.fulfilled, (state, action) => {
        state.loading = false
        state.promoList = action.payload.data
      })
      .addCase(getPromoApi.rejected, (state) => {
        state.loading = false
        state.promoList = []
      })

    builder
      .addCase(getPromoByIdApi.pending, (state) => {
        state.loading = true
      })
      .addCase(getPromoByIdApi.fulfilled, (state, action) => {
        state.loading = false
        state.form = action.payload.data
      })
      .addCase(getPromoByIdApi.rejected, (state) => {
        state.loading = false
        state.form = initialForm
      })
  },
})

export const { setTableData, setForm, resetForm, setLoading } = dataSlice.actions

export default dataSlice.reducer
