import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchConnectorApi, fetchConnectorByChargerApi } from 'services/ConnectorService'

const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: '',
  sort: {
    order: '',
    key: '',
  },
}

const initialForm = {
  chargerId: '',
  connectorOcppId: '',
  name: '',
  power: undefined,
  kwhLimitPrice: undefined,
  timeLimitPrice: undefined,
  coffeeFee: 0,
  price: 0,
  triger: 0,
  timeout: 0,
  serviceFee: 0,
  applicationFee: 0,
}

export const getConnectorApi = createAsyncThunk('connector/getConnector', async () => {
  const response = await fetchConnectorApi()
  return response.data
})

export const getConnectorByIdApi = createAsyncThunk('charger/getConnectorById', async (id) => {
  const response = await fetchConnectorApi(id)
  return response.data
})

export const getConnectorByChargerIdApi = createAsyncThunk(
  'charger/getConnectorByChargerId',
  async (chargerId) => {
    const response = await fetchConnectorByChargerApi(chargerId)
    return response.data
  },
)

const dataSlice = createSlice({
  name: 'connector/data',
  initialState: {
    loading: true,
    connectorList: [],
    form: initialForm,
    tableData: initialTableData,
    tableSearch: '',
  },
  reducers: {
    setTableData: (state, action) => {
      state.tableData = action.payload
    },
    setTableSearch: (state, action) => {
      state.tableSearch = action.payload
    },
    setForm: (state, action) => {
      state.form = action.payload
    },
    resetForm: (state) => {
      state.form = initialForm
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getConnectorApi.fulfilled, (state, action) => {
        state.loading = false
        state.connectorList = action.payload.data
      })
      .addCase(getConnectorApi.pending, (state) => {
        state.loading = true
      })
      .addCase(getConnectorApi.rejected, (state) => {
        state.loading = false
        state.connectorList = []
      })

    builder
      .addCase(getConnectorByIdApi.fulfilled, (state, action) => {
        state.loading = false
        state.form = action.payload.data
      })
      .addCase(getConnectorByIdApi.pending, (state) => {
        state.loading = true
      })
      .addCase(getConnectorByIdApi.rejected, (state) => {
        state.loading = false
        state.form = initialForm
      })

    builder
      .addCase(getConnectorByChargerIdApi.fulfilled, (state, action) => {
        state.loading = false
        state.connectorList = action.payload.data
      })
      .addCase(getConnectorByChargerIdApi.pending, (state) => {
        state.loading = true
      })
      .addCase(getConnectorByChargerIdApi.rejected, (state) => {
        state.loading = false
        state.connectorList = []
      })
  },
})

export const { setTableData, setTableSearch, setForm, resetForm } = dataSlice.actions

export default dataSlice.reducer
