import ApiService from './ApiService'
import { SETTING_MANAGEMENT } from 'constants/api.constant'

// SYSTEM SERVICE
export const fetchSystemApi = async (id = '') =>
  ApiService.fetchData({
    url: SETTING_MANAGEMENT + '/systems/' + id,
    method: 'get',
  })

export const createSystemApi = async (data) =>
  ApiService.fetchData({
    url: SETTING_MANAGEMENT + '/systems',
    method: 'post',
    data,
  })

export const updateSystemApi = async (data, id) =>
  ApiService.fetchData({
    url: SETTING_MANAGEMENT + '/systems/' + id,
    method: 'patch',
    data,
  })

export const deleteSystemApi = async (id) =>
  ApiService.fetchData({
    url: SETTING_MANAGEMENT + '/systems/' + id,
    method: 'delete',
  })

// MENU SERVICE
export const fetchMenuApi = async (id = '') =>
  ApiService.fetchData({
    url: SETTING_MANAGEMENT + '/menus/' + id,
    method: 'get',
  })

export const createMenuApi = async (data) =>
  ApiService.fetchData({
    url: SETTING_MANAGEMENT + '/menus',
    method: 'post',
    data,
  })

export const updateMenuApi = async (data, id) =>
  ApiService.fetchData({
    url: SETTING_MANAGEMENT + '/menus/' + id,
    method: 'patch',
    data,
  })

export const deleteMenuApi = async (id) =>
  ApiService.fetchData({
    url: SETTING_MANAGEMENT + '/menus/' + id,
    method: 'delete',
  })

// SUBMENU SERVICE
export const fetchSubMenuApi = async (id = '') =>
  ApiService.fetchData({
    url: SETTING_MANAGEMENT + '/sub-menus/' + id,
    method: 'get',
  })

export const createSubMenuApi = async (data) =>
  ApiService.fetchData({
    url: SETTING_MANAGEMENT + '/sub-menus',
    method: 'post',
    data,
  })

export const updateSubMenuApi = async (data, id) =>
  ApiService.fetchData({
    url: SETTING_MANAGEMENT + '/sub-menus/' + id,
    method: 'patch',
    data,
  })

export const deleteSubMenuApi = async (id) =>
  ApiService.fetchData({
    url: SETTING_MANAGEMENT + '/sub-menus/' + id,
    method: 'delete',
  })
