import { CONFIG } from "configs/app.config"

export const APP_NAME = CONFIG.REACT_APP_NAME
export const PERSIST_STORE_NAME = 'admin'
export const REDIRECT_URL_KEY = 'ref'

export const BRAND_NAME = CONFIG.REACT_APP_BRAND_NAME || 'Samudera Recharge'
export const BRAND_ID = CONFIG.REACT_APP_BRAND_ID || 'samudera'
export const SIDEBAR_BACKGROUND = CONFIG.SIDEBAR_BACKGROUND
export const SIDEBAR_BACKGROUND_COLOR = CONFIG.REACT_APP_SIDEBAR_BACKGROUND_COLOR || '#18181b'

export const OPTIONS_YEAR_INVOICE = CONFIG.YEAR_INVOICE || 5

export const COFFEE_RECONCILIATION_FEATURE = CONFIG.REACT_APP_COFFEE_RECONCILIATION_FEATURE || false
export const FLEET_FEATURE = CONFIG.REACT_APP_FLEET_FEATURE || false
