import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchVehicleApi, fetchVehicleByIdApi } from 'services/VehicleService'

export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  tableSearch: '',
  mitra: '',
  district: '',
}

const initialForm = {
  brand: '',
  model: '',
  connector: '',
  secondConnector: '',
  year: 0,
  capacity: 0, //kWh
  imageId: '', // upload
}

export const getVehicleApi = createAsyncThunk('vehicle/getVehicle', async (param) => {
  const response = await fetchVehicleApi(param)
  return response.data
})

export const getVehicleByIdApi = createAsyncThunk('station/getStationById', async (id) => {
  const response = await fetchVehicleByIdApi(id)
  return response.data
})

const dataSlice = createSlice({
  name: 'vehicle/data',
  initialState: {
    loading: true,
    vehicleList: [],
    form: initialForm,
    tableData: initialTableData,
    tableSearch: '',
  },
  reducers: {
    setTableData: (state, action) => {
      state.tableData = action.payload
    },
    setTableSearch: (state, action) => {
      state.tableSearch = action.payload
    },
    setForm: (state, action) => {
      state.form = action.payload
    },
    resetForm: (state) => {
      state.form = initialForm
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVehicleApi.fulfilled, (state, action) => {
        console.log('action.payload.data.data', action.payload.data)
        state.loading = false
        state.vehicleList = action.payload.data.vehicles
        state.tableData.total = action.payload.data.totalData
      })
      .addCase(getVehicleApi.pending, (state) => {
        state.loading = true
      })
      .addCase(getVehicleApi.rejected, (state) => {
        state.loading = false
        state.vehicleList = []
      })

    builder
      .addCase(getVehicleByIdApi.fulfilled, (state, action) => {
        state.loading = false
        state.form = action.payload.data
      })
      .addCase(getVehicleByIdApi.pending, (state) => {
        state.loading = true
      })
      .addCase(getVehicleByIdApi.rejected, (state) => {
        state.loading = false
        state.form = initialForm
      })
  },
})

export const { setTableData, setTableSearch, setForm, resetForm } = dataSlice.actions

export default dataSlice.reducer
