import { createSlice } from '@reduxjs/toolkit'

const stateSlice = createSlice({
  name: 'charger/state',
  initialState: {
    deleteConfirmation: false,
    qrDialog: false,
    actionId: '',
    cpId: '',
    qrId: '',
  },

  reducers: {
    setDescriptionDialog: (state, action) => {
      state.descriptionDialog = action.payload
    },
    toggleDeleteConfirmation: (state, action) => {
      state.deleteConfirmation = action.payload
    },
    setActionId: (state, action) => {
      state.actionId = action.payload
    },
    setCpId: (state, action) => {
      state.cpId = action.payload
    },
    setQrDialog: (state, action) => {
      state.qrDialog = action.payload
    },
    setQrId: (state, action) => {
      state.qrId = action.payload
    },
  },
})

export const {
  toggleDeleteConfirmation,
  setDescriptionDialog,
  setActionId,
  setCpId,
  setQrDialog,
  setQrId,
} = stateSlice.actions

export default stateSlice.reducer
