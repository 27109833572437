import { CHARGERS_COMMAND } from 'constants/api.constant'
import ApiService from './ApiService'
import { CHARGING_MANAGEMENT } from 'constants/api.constant'

export const fetchChargerApi = async (param) =>
  ApiService.fetchData({
    url:
      CHARGING_MANAGEMENT +
      '/chargers?page=' +
      param.pageIndex +
      '&size=' +
      param.pageSize +
      '&search=' +
      param.tableSearch,
    method: 'get',
  })

export const fetchChargerByIdApi = async (id) =>
  ApiService.fetchData({
    url: CHARGING_MANAGEMENT + '/chargers/' + id,
    method: 'get',
  })

export const fetchChargerByStationApi = async (id) =>
  ApiService.fetchData({
    url: CHARGING_MANAGEMENT + '/chargers/by-station-id/' + id,
    method: 'get',
  })

export const createChargerApi = async (data) =>
  ApiService.fetchData({
    url: CHARGING_MANAGEMENT + '/chargers',
    method: 'post',
    data,
  })

export const updateChargerApi = async (data, id) =>
  ApiService.fetchData({
    url: CHARGING_MANAGEMENT + '/chargers/' + id,
    method: 'patch',
    data,
  })

export const deleteChargerApi = async (id) =>
  ApiService.fetchData({
    url: CHARGING_MANAGEMENT + '/chargers/' + id,
    method: 'delete',
  })

export const chargerLogApi = async (data) =>
  ApiService.fetchData({
    url: CHARGERS_COMMAND + '/logs',
    method: 'post',
    data,
  })
