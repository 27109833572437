import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchCategoriesApi } from 'services/CategoryService'

const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  tableSearch: '',
  sort: {
    order: '',
    key: '',
  },
}

const initialForm = {
  name: '',
}

export const getCategoriesApi = createAsyncThunk('categories/getCategoriesApi', async (param) => {
  const response = await fetchCategoriesApi(param)
  return response.data
})

const dataSlice = createSlice({
  name: 'category/data',
  initialState: {
    loading: true,
    categoriesList: [],
    form: initialForm,
    tableData: initialTableData,
    tableSearch: '',
  },
  reducers: {
    setTableData: (state, action) => {
      state.tableData = action.payload
    },
    setTableSearch: (state, action) => {
      state.tableSearch = action.payload
    },
    setForm: (state, action) => {
      state.form = action.payload
    },
    resetForm: (state) => {
      state.form = initialForm
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategoriesApi.pending, (state) => {
        state.loading = true
      })
      .addCase(getCategoriesApi.fulfilled, (state, action) => {
        state.loading = false
        state.categoriesList = action.payload.data
        state.tableData.total = action.payload.data.totalData
      })
      .addCase(getCategoriesApi.rejected, (state) => {
        state.loading = false
        state.categoriesList = []
      })
  },
})

export const { setTableData, setTableSearch, setForm, resetForm } = dataSlice.actions

export default dataSlice.reducer
