import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchBannerApi, fetchBannerByIdApi } from 'services/BannerService'

const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  tableSearch: '',
}

const initialForm = {
  name: '',
  content: '',
  imageId: '',
}

export const getBannersApi = createAsyncThunk('banners/getBannersApi', async (param) => {
  const response = await fetchBannerApi(param)
  return response.data
})

export const getBannerByIdApi = createAsyncThunk('banners/getBannerByIdApi', async (id) => {
  const response = await fetchBannerByIdApi(id)
  return response.data
})

const dataSlice = createSlice({
  name: 'banner/data',
  initialState: {
    loading: true,
    form: initialForm,
    tableData: initialTableData,
    bannerList: [],
  },
  reducers: {
    setTableData: (state, action) => {
      state.tableData = action.payload
    },
    setForm: (state, action) => {
      state.form = action.payload
    },
    resetForm: (state) => {
      state.form = initialForm
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBannersApi.pending, (state) => {
        state.loading = true
      })
      .addCase(getBannersApi.fulfilled, (state, action) => {
        state.loading = false
        state.bannerList = action.payload.data.banners
        state.tableData.total = action.payload.data.totalData
      })
      .addCase(getBannersApi.rejected, (state) => {
        state.loading = false
        state.bannerList = []
      })

    builder
      .addCase(getBannerByIdApi.pending, (state) => {
        state.loading = true
      })
      .addCase(getBannerByIdApi.fulfilled, (state, action) => {
        state.loading = false
        state.form = action.payload.data
      })
      .addCase(getBannerByIdApi.rejected, (state) => {
        state.loading = false
        state.form = initialForm
      })
  },
})

export const { setTableData, setForm, resetForm } = dataSlice.actions

export default dataSlice.reducer
