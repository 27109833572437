import { OPTIONS_YEAR_INVOICE } from 'constants/app.constant'
import { fetchInvoiceApi } from 'services/ReportService'

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit')

const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: '',
  status: '',
  month: new Date().getMonth() + 1,
  year: new Date().getFullYear(),
  sort: {
    order: '',
    key: '',
  },
}

const yearOptions = () => {
  let yearArray = []
  for (var i = 0; i < OPTIONS_YEAR_INVOICE; i++) {
    yearArray.push({ label: new Date().getFullYear() - i, value: new Date().getFullYear() - i })
  }
  return yearArray
}

export const getRFIDInvoice = createAsyncThunk('rfid-invoice', async (params) => {
  const res = await fetchInvoiceApi(params)
  return res.data.data
})

const dataSlice = createSlice({
  name: 'rfidInvoice',
  initialState: {
    invoiceList: [],
    loading: false,
    tableData: initialTableData,
    yearOption: yearOptions(),
  },
  reducers: {
    setTableDataInvoice: (state, action) => {
      state.tableData = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRFIDInvoice.pending, (state) => {
        state.loading = true
      })
      .addCase(getRFIDInvoice.fulfilled, (state, action) => {
        state.loading = false
        state.invoiceList = action.payload.invoices
        state.tableData.total = action.payload.totalData
      })
      .addCase(getRFIDInvoice.rejected, (state) => {
        state.loading = false
        state.invoiceList = []
      })
  },
})

export const { setTableDataInvoice } = dataSlice.actions
export default dataSlice.reducer
