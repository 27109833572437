import * as services from 'services/FeedbackConfigService'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const getFeedbackConfigApi = createAsyncThunk(
  'feedbackConfig/getFeedbackConfig',
  async (params) => {
    const response = await services.fetchFeedbackConfigApi(params)
    return response.data.data
  },
)

const dataSlice = createSlice({
  name: 'feedbackConfig/data',
  initialState: {
    loading: true,
    createOrUpdateLoading: false,
    // Table Metadata?
    tableData: {
      total: 0,
      pageIndex: 1,
      pageSize: 10,
      tableSearch: '',
    },
    feedbackConfigList: [],
    form: {
      id: undefined,
      title: '',
      description: '',
      reviewDescription: '',
    },
    deleteID: undefined,
  },
  reducers: {
    setFormID: (state, action) => {
      state.form.id = action.payload
    },
    setForm: (state, action) => {
      state.form = action.payload
    },
    setDeletedID: (state, action) => {
      state.deleteID = action.payload
    },
    resetForm: (state) => {
      state.form = {
        title: '',
        description: '',
        reviewDescription: '',
      }
    },
  },
  extraReducers: (builder) => {
    // GET listener
    builder
      .addCase(getFeedbackConfigApi.pending, (state) => {
        state.loading = true
      })
      .addCase(getFeedbackConfigApi.fulfilled, (state, action) => {
        state.loading = false
        state.feedbackConfigList = action.payload
      })
      .addCase(getFeedbackConfigApi.rejected, (state) => {
        state.loading = false
      })
  },
})

export const actions = dataSlice.actions
export const storeServices = {
  getFeedbackConfigApi,
}

export default dataSlice.reducer
