import { createSlice } from '@reduxjs/toolkit'

const stateSlice = createSlice({
  name: 'transaction/state',
  initialState: {
    dialog: false,
    actionId: '',
  },

  reducers: {
    toggleDialog: (state, action) => {
      state.dialog = action.payload
    },
    setActionId: (state, action) => {
      state.actionId = action.payload
    },
  },
})

export const { toggleDialog, setActionId } = stateSlice.actions

export default stateSlice.reducer
