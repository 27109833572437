import ApiService from './ApiService'
import { CONTENT_MANAGEMENT } from 'constants/api.constant'

export const fetchSplashScreenApi = async (param) =>
  ApiService.fetchData({
    url:
      CONTENT_MANAGEMENT +
      '/splash-screens?page=' +
      param.pageIndex +
      '&size=' +
      param.pageSize +
      '&search=' +
      param.tableSearch,
    method: 'get',
  })

export const fetchSplashScreenByIdApi = async (id) =>
  ApiService.fetchData({
    url: CONTENT_MANAGEMENT + '/splash-screens/' + id,
    method: 'get',
  })

export const createSplashScreenApi = async (data) =>
  ApiService.fetchData({
    url: CONTENT_MANAGEMENT + '/splash-screens',
    method: 'post',
    data,
  })

export const updateSplashScreenApi = async (data, id) =>
  ApiService.fetchData({
    url: CONTENT_MANAGEMENT + '/splash-screens/' + id,
    method: 'patch',
    data,
  })

export const deleteSplashScreenApi = async (id) =>
  ApiService.fetchData({
    url: CONTENT_MANAGEMENT + '/splash-screens/' + id,
    method: 'delete',
  })
