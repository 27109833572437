import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  fetchMitraApi,
  fetchMitraByIdApi,
  fetchTokenOcppApi,
  fetchUserApi,
  fetchUserById,
  fetchUserMitraApi,
  fetchUserProfileApi,
} from 'services/UserService'

export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  tableSearch: '',
}

const initialForm = {
  email: '',
  password: '',
  roleId: '',
  name: '',
}

const initialMitras = {
  name: '',
  userId: '',
  mitraCode: '',
}

const initialProfile = {
  name: '',
  email: '',
  imageId: '',
  phone: '',
  address: '',
  userId: '',
  districtId: '',
  carType: '',
  carBrand: '',
  carYear: '',
  policeNo: '',
}

export const getUsersApi = createAsyncThunk('users/getUsers', async (param) => {
  const response = await fetchUserApi(param)
  return response.data
})

export const getUserApi = createAsyncThunk('users/getUser', async (id) => {
  const response = await fetchUserById(id)
  return response.data
})

export const getProfileByUserIdApi = createAsyncThunk('users/getProfileByUserId', async (id) => {
  const response = await fetchUserProfileApi(id)
  return response.data
})

export const getMitrasApi = createAsyncThunk('users/getMitras', async (param) => {
  const response = await fetchMitraApi(param)
  return response.data
})

export const getMitraByIdApi = createAsyncThunk('users/getMitraById', async (id) => {
  const response = await fetchMitraByIdApi(id)
  return response.data
})

export const getUserMitrasApi = createAsyncThunk('users/getUserMitras', async (id) => {
  const response = await fetchUserMitraApi(id)
  return response.data
})

export const getTokenOcppApi = createAsyncThunk('users/getTokenOcpp', async () => {
  const response = await fetchTokenOcppApi()
  return response.data
})

const dataSlice = createSlice({
  name: 'user/data',
  initialState: {
    loading: false,
    loadingMitra: false,
    userList: [],
    mitraList: [],
    tokenList: [],
    form: initialForm,
    formMitra: initialMitras,
    profile: initialProfile,
    tableDataCustomer: initialTableData,
    tableDataMitra: initialTableData,
    tableDataPartner: initialTableData,
  },
  reducers: {
    setTableDataPartner: (state, action) => {
      state.tableDataPartner = action.payload
    },
    setTableDataCustomer: (state, action) => {
      state.tableDataCustomer = action.payload
    },
    setTableDataMitra: (state, action) => {
      state.tableDataMitra = action.payload
    },
    setForm: (state, action) => {
      state.form = action.payload
    },
    resetForm: (state) => {
      state.form = initialForm
      state.formMitra = initialMitras
    },
    setProfile: (state, action) => {
      state.profile = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsersApi.pending, (state) => {
        state.loading = true
      })
      .addCase(getUsersApi.fulfilled, (state, action) => {
        state.loading = false
        state.userList = action.payload.data.users
        if (
          action.payload.data.totalData !== 0 &&
          action.payload.data.users[0].Role.name === 'Customer'
        ) {
          state.tableDataCustomer.total = action.payload.data.totalData
        } else {
          state.tableDataPartner.total = action.payload.data.totalData
        }
      })
      .addCase(getUsersApi.rejected, (state) => {
        state.loading = false
        state.userList = []
      })

    builder
      .addCase(getUserApi.pending, (state) => {
        state.loading = true
      })
      .addCase(getUserApi.fulfilled, (state, action) => {
        state.loading = false
        state.form = action.payload.data
      })
      .addCase(getUserApi.rejected, (state) => {
        state.loading = false
        state.form = initialForm
      })

    builder
      .addCase(getProfileByUserIdApi.pending, (state) => {
        state.loading = true
      })
      .addCase(getProfileByUserIdApi.fulfilled, (state, action) => {
        state.loading = false
        state.profile = action.payload.data
      })
      .addCase(getProfileByUserIdApi.rejected, (state) => {
        state.loading = false
        state.profile = initialProfile
      })

    builder
      .addCase(getMitrasApi.pending, (state) => {
        state.loadingMitra = true
      })
      .addCase(getMitrasApi.fulfilled, (state, action) => {
        state.loadingMitra = false
        state.mitraList = action.payload.data.mitras
        state.tableDataMitra.total = action.payload.data.totalData
      })
      .addCase(getMitrasApi.rejected, (state) => {
        state.loadingMitra = false
        state.mitraList = []
      })

    builder
      .addCase(getMitraByIdApi.pending, (state) => {
        state.loadingMitra = true
      })
      .addCase(getMitraByIdApi.fulfilled, (state, action) => {
        state.loadingMitra = false
        state.formMitra = action.payload.data
      })
      .addCase(getMitraByIdApi.rejected, (state) => {
        state.loadingMitra = false
        state.formMitra = initialMitras
      })

    builder
      .addCase(getUserMitrasApi.pending, (state) => {
        state.loadingMitra = true
      })
      .addCase(getUserMitrasApi.fulfilled, (state, action) => {
        state.loadingMitra = false
        state.mitraList = action.payload.data
      })
      .addCase(getUserMitrasApi.rejected, (state) => {
        state.loadingMitra = false
        state.mitraList = []
      })

    builder
      .addCase(getTokenOcppApi.pending, (state) => {
        state.loading = true
      })
      .addCase(getTokenOcppApi.fulfilled, (state, action) => {
        state.loading = false
        state.tokenList = action.payload.data
      })
      .addCase(getTokenOcppApi.rejected, (state) => {
        state.loading = false
        state.tokenList = []
      })
  },
})

export const {
  setTableDataPartner,
  setTableDataCustomer,
  setTableDataMitra,
  setForm,
  resetForm,
  setProfile,
} = dataSlice.actions

export default dataSlice.reducer
