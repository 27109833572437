import { USER_MANAGEMENT } from 'constants/api.constant'
import ApiService from './ApiService'

export const fetchFleetApi = async (param) => {
  const params = {
    page: param.pageIndex,
    size: param.pageSize,
  }

  if (param.mitraId) {
    params.mitraId = param.mitraId
  }
  if (param.stationId) {
    params.stationId = param.stationId
  }
  if (param.email) {
    params.customerEmail = param.email
  }
  
  return ApiService.fetchData({
    url: USER_MANAGEMENT + '/stationAccess',
    method: 'get',
    params: params,
  })
}

export const createFleetApi = async (data) =>
  ApiService.fetchData({
    url: USER_MANAGEMENT + '/stationAccess',
    method: 'post',
    data,
  })

export const updateFleetApi = async (id, data) =>
  ApiService.fetchData({
    url: USER_MANAGEMENT + '/stationAccess/' + id,
    method: 'patch',
    data,
  })

export const deleteFleetApi = async (id) =>
  ApiService.fetchData({
    url: USER_MANAGEMENT + '/stationAccess/' + id,
    method: 'delete',
  })

export const getPrivateStationApi = async () =>
  ApiService.fetchData({
    url: USER_MANAGEMENT + '/stationAccess/private-stations',
    method: 'get',
  })
