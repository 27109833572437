import { createSlice } from '@reduxjs/toolkit'

const stateSlice = createSlice({
  name: 'fleet/state',
  initialState: {
    deleteConfirmation: false,
    dialog: false,
    isEdit: false,
    form: { customerEmail: '', stationId: '', mitraId: '', free: false }, 
    actionId: '',
  },

  reducers: {
    toggleDeleteConfirmation: (state, action) => {
      state.deleteConfirmation = action.payload
    },
    toggleDialog: (state, action) => {
      state.dialog = action.payload.dialog
      state.isEdit = action.payload.isEdit
      state.form = action.payload.form
      state.actionId = action.payload.id || ''
    },
    setActionId: (state, action) => {
      state.actionId = action.payload
    },
  },
})

export const { toggleDeleteConfirmation, toggleDialog, setActionId } = stateSlice.actions

export default stateSlice.reducer
