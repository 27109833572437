import ApiService from './ApiService'
import { DASHBOARD_MANAGEMENT, TRANSACTION_MANAGEMENT } from 'constants/api.constant'

export const getTransactionCount = async (param) =>
  ApiService.fetchData({
    url:
      DASHBOARD_MANAGEMENT +
      '/count-transaction?dateStart=' +
      param.start +
      '&dateEnd=' +
      param.end,
    method: 'get',
  })

export const getChargingCount = async () =>
  ApiService.fetchData({
    url: DASHBOARD_MANAGEMENT + '/count-charging-management',
    method: 'get',
  })

export const getPaymentAmount = async () =>
  ApiService.fetchData({
    url: DASHBOARD_MANAGEMENT + '/summary-amount-mitra',
    method: 'get',
  })

export const getKoinCasApi = async () =>
  ApiService.fetchData({
    url: DASHBOARD_MANAGEMENT + '/count-koincas',
    method: 'get',
  })

export const getDashboardChartApi = async () => {
  let previousDate = new Date()
  previousDate.setDate(new Date().getDate() - 15)
  let startDate = previousDate.toISOString().slice(0, 10)
  const endpoint = `${TRANSACTION_MANAGEMENT}/transactions/dashboard?dateStart=${startDate}`
  return ApiService.fetchData({
    url: endpoint,
    method: 'get',
  })
}
