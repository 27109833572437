import ApiService from './ApiService'
import { TRANSACTION_MANAGEMENT } from 'constants/api.constant'

export const fetchRFIDsApi = async (id) => {
  let url = TRANSACTION_MANAGEMENT + '/admin/rfid-cards'
  if (id) {
    url = `${url}?search=${id}`
  }
  return ApiService.fetchData({
    url,
    method: 'get',
  })
}

export const createRFIDApi = async (data) =>
  ApiService.fetchData({
    url: TRANSACTION_MANAGEMENT + '/admin/rfid-cards',
    method: 'post',
    data,
  })

export const updateRFIDApi = async (data, id) =>
  ApiService.fetchData({
    url: TRANSACTION_MANAGEMENT + '/admin/rfid-cards/' + id,
    method: 'patch',
    data,
  })

export const updateRFIDBalanceApi = async (data, id) =>
  ApiService.fetchData({
    url: TRANSACTION_MANAGEMENT + '/admin/rfid-cards/' + id + '/topup',
    method: 'patch',
    data,
  })

export const deleteRFIDApi = async (id) =>
  ApiService.fetchData({
    url: TRANSACTION_MANAGEMENT + '/admin/rfid-cards/' + id,
    method: 'delete',
  })

export const getRFIDStatusApi = async (param) =>
  ApiService.fetchData({
    url: TRANSACTION_MANAGEMENT + '/admin/rfid-cards/order',
    method: 'get',
    params: {
      page: param.pageIndex,
      size: param.pageSize,
    },
  })

export const updateRFIDStatusApi = async (id, data) =>
  ApiService.fetchData({
    url: TRANSACTION_MANAGEMENT + '/admin/rfid-cards/order/' + id,
    method: 'patch',
    data,
  })
