import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchFleetApi, getPrivateStationApi } from 'services/FleetService'

const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  email: '',
  mitraId: '',
  stationId: ''
}

export const getPrivateStationOptions = createAsyncThunk('fleet/getStation', async () => {
  const resp = await getPrivateStationApi()
  return resp.data
})

export const getFleetManagement = createAsyncThunk('fleet/getList', async (param) => {
  const resp = await fetchFleetApi(param)
  return resp.data
})

const dataSlice = createSlice({
  name: 'fleet/data',
  initialState: {
    loading: true,
    fleetList: [],
    stationList: [],
    form: { name: '', description: '' },
    tableData: initialTableData,
    tableSearch: '',
  },
  reducers: {
    setTableData: (state, action) => {
      state.tableData = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFleetManagement.pending, (state) => {
        state.loading = true
      })
      .addCase(getFleetManagement.fulfilled, (state, action) => {
        state.loading = false
        state.fleetList = action.payload.data.data
        state.tableData.total = action.payload.data.count
      })
      .addCase(getFleetManagement.rejected, (state) => {
        state.loading = false
        state.fleetList = []
      })

    builder.addCase(getPrivateStationOptions.fulfilled, (state, action) => {
      state.stationList =
        action.payload.data?.map((item) => ({ label: item.name, value: item.id })) || []
    })
  },
})

export const { setTableData, setTableSearch, setForm, resetForm } = dataSlice.actions

export default dataSlice.reducer
