import ApiService from './ApiService'
import { CHARGING_MANAGEMENT } from 'constants/api.constant'

export const fetchConnectorApi = async (id = '') =>
  ApiService.fetchData({
    url: CHARGING_MANAGEMENT + '/connectors/' + id,
    method: 'get',
  })

export const fetchConnectorByChargerApi = async (id) =>
  ApiService.fetchData({
    url: CHARGING_MANAGEMENT + '/connectors/by-charger-id/' + id,
    method: 'get',
  })

export const createConnectorApi = async (data) =>
  ApiService.fetchData({
    url: CHARGING_MANAGEMENT + '/connectors',
    method: 'post',
    data,
  })

export const updateConnectorApi = async (data, id) =>
  ApiService.fetchData({
    url: CHARGING_MANAGEMENT + '/connectors/' + id,
    method: 'patch',
    data,
  })

export const deleteConnectorApi = async (id) =>
  ApiService.fetchData({
    url: CHARGING_MANAGEMENT + '/connectors/' + id,
    method: 'delete',
  })
