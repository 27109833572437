import ApiService from './ApiService'
import { CHARGING_MANAGEMENT } from 'constants/api.constant'

export const fetchStationApi = async (param) => {
  const queryParams = {
    page: param.pageIndex,
    size: param.pageSize,
    search: param.tableSearch,
    searchByDistrict: `${encodeURI(param.district)}`,
    filter: param.mitra,
  }
  if (param.privateAccess !== '') {
    queryParams.privateAccess = param.privateAccess
  }
  return ApiService.fetchData({
    url: CHARGING_MANAGEMENT + '/stations',
    method: 'get',
    params: queryParams,
  })
}

export const fetchStationByIdApi = async (id) =>
  ApiService.fetchData({
    url: CHARGING_MANAGEMENT + '/stations/' + id,
    method: 'get',
  })

export const createStationApi = async (data) =>
  ApiService.fetchData({
    url: CHARGING_MANAGEMENT + '/stations',
    method: 'post',
    data,
  })

export const updateStationApi = async (data, id) =>
  ApiService.fetchData({
    url: CHARGING_MANAGEMENT + '/stations/' + id,
    method: 'patch',
    data,
  })

export const deleteStationApi = async (id) =>
  ApiService.fetchData({
    url: CHARGING_MANAGEMENT + '/stations/' + id,
    method: 'delete',
  })
