import { CONFIG } from "configs/app.config"

function setMetaTag(name, content) {
  let meta = document.querySelector(`meta[name="${name}"]`);
  
  if (meta) {
      meta.setAttribute('content', content);
  } else {
      meta = document.createElement('meta');
      meta.setAttribute('name', name);
      meta.setAttribute('content', content);
      document.head.appendChild(meta);
  }
}

export function metadataEnv() {
  let link = document.querySelector("link[rel*='icon']") || document.createElement('link')
  link.rel = 'icon'
  link.href = `/${CONFIG.REACT_APP_FAVICON}`
  document.getElementsByTagName('head')[0].appendChild(link)

  let manifest = document.querySelector("link[rel*='manifest']") || document.createElement('manifest')
  manifest.rel = 'manifest'
  manifest.href = `/${CONFIG.REACT_APP_MANIFEST}`
  document.getElementsByTagName('head')[0].appendChild(manifest)

  setMetaTag('description', `${CONFIG.REACT_APP_DESC}`)

}
