import { CHARGING_MANAGEMENT } from 'constants/api.constant'
import ApiService from './ApiService'

export const fetchFeedbackApi = async (param) =>
  ApiService.fetchData({
    url:
      CHARGING_MANAGEMENT +
      '/feedBacks?page=' +
      param.pageIndex +
      '&size=' +
      param.pageSize +
      '&search=' +
      param.tableSearch.replaceAll('#', '%23'),
    method: 'get',
  })

export const fetchFeedbackByUserApi = async (id) =>
  ApiService.fetchData({
    url: CHARGING_MANAGEMENT + '/feedBacks/by-user-id/' + id,
    method: 'get',
  })

export const createFeedbackApi = async (data) =>
  ApiService.fetchData({
    url: CHARGING_MANAGEMENT + '/feedBacks',
    method: 'post',
    data,
  })

export const updateFeedbackApi = async (data, id) =>
  ApiService.fetchData({
    url: CHARGING_MANAGEMENT + '/feedBacks/' + id,
    method: 'patch',
    data,
  })

export const deleteFeedbackApi = async (id) =>
  ApiService.fetchData({
    url: CHARGING_MANAGEMENT + '/feedBacks/' + id,
    method: 'delete',
  })
