import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  getChargingCount,
  getDashboardChartApi,
  getKoinCasApi,
  getPaymentAmount,
  getTransactionCount,
} from 'services/DashboardService'

const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: '',
  sort: {
    order: '',
    key: '',
  },
}

export const getTransactionAmount = createAsyncThunk('dashboard/transaction', async (param) => {
  const response = await getTransactionCount(param)
  return response.data
})

export const getChargingCountApi = createAsyncThunk('dashboard/charging', async () => {
  const response = await getChargingCount()
  return response.data
})

export const getPaymentApi = createAsyncThunk('payment/data', async () => {
  const response = await getPaymentAmount()
  return response.data
})

export const getKoinCas = createAsyncThunk('dashboard/koincas', async () => {
  const response = await getKoinCasApi()
  return response.data
})

export const dashboardChart = createAsyncThunk('dashboard/chart', async () => {
  const response = await getDashboardChartApi()
  return response.data
})

export const initialFilterData = {
  status: '',
}

const dataSlice = createSlice({
  name: 'dashboard/data',
  initialState: {
    loadingCharging: false,
    loadingAmount: true,
    loadingGraphic: false,
    loadingPayment: false,
    loadingKoinCas: false,
    dataKoinCas: '',
    dataCharging: '',
    dataAmount: '',
    dataGraphic: '',
    paymentList: [],
    tableData: initialTableData,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTransactionAmount.pending, (state) => {
        state.loadingAmount = true
      })
      .addCase(getTransactionAmount.fulfilled, (state, action) => {
        state.loadingAmount = false
        state.dataAmount = action.payload.data
      })
      .addCase(getTransactionAmount.rejected, (state) => {
        state.loadingAmount = false
        state.dataAmount = ''
      })

    builder
      .addCase(getChargingCountApi.pending, (state) => {
        state.loadingCharging = true
      })
      .addCase(getChargingCountApi.fulfilled, (state, action) => {
        state.loadingCharging = false
        state.dataCharging = action.payload.data
      })
      .addCase(getChargingCountApi.rejected, (state) => {
        state.loadingCharging = false
        state.dataCharging = ''
      })

    builder
      .addCase(getPaymentApi.pending, (state) => {
        state.loadingPayment = true
      })
      .addCase(getPaymentApi.fulfilled, (state, action) => {
        state.loadingPayment = false
        state.paymentList = action.payload.data
      })
      .addCase(getPaymentApi.rejected, (state) => {
        state.loadingPayment = false
        state.paymentList = []
      })

    builder
      .addCase(getKoinCas.pending, (state) => {
        state.loadingKoinCas = true
      })
      .addCase(getKoinCas.fulfilled, (state, action) => {
        state.loadingKoinCas = false
        state.dataKoinCas = action.payload.data
      })
      .addCase(getKoinCas.rejected, (state) => {
        state.loadingKoinCas = false
        state.dataKoinCas = ''
      })

      builder
      .addCase(dashboardChart.pending, (state) => {
        state.loadingGraphic = true
      })
      .addCase(dashboardChart.fulfilled, (state, action) => {
        state.loadingGraphic = false
        state.dataGraphic = action.payload.data
      })
      .addCase(dashboardChart.rejected, (state) => {
        state.loadingGraphic = false
        state.dataGraphic = ''
      })
  },
})

export default dataSlice.reducer
