import { VERSION } from 'constants/api.constant'

export const CONFIG =
  process.env.NODE_ENV !== 'production' ? process.env : window.__RUNTIME_CONFIG__

console.debug({CONFIG})

const appConfig = {
  apiPrefix: CONFIG.REACT_APP_BASE_URL + VERSION,
  authenticatedEntryPath: '/dashboard',
  unAuthenticatedEntryPath: '/sign-in',
  tourPath: '/',
  enableMock: false,
}

export default appConfig
