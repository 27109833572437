import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchFeedbackReportApi } from 'services/FeedbackReportService'
import { fetchFeedbackApi } from 'services/FeedbackService'

const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  tableSearch: '',
}

const initialForm = {
  userId: '',
  stationId: '',
  comment: '',
  rating: '',
}

export const getFeedbackApi = createAsyncThunk('feedback/getFeedback', async (param) => {
  const response = await fetchFeedbackApi(param)
  return response.data
})

export const getFeedbackReportApi = createAsyncThunk(
  'feedbackReport/getFeedbackReport',
  async (param) => {
    const response = await fetchFeedbackReportApi(param)
    return response.data
  },
)

const dataSlice = createSlice({
  name: 'feedback/data',
  initialState: {
    loading: true,
    loadingReport: true,
    feedbackList: [],
    feedbackReportList: [],
    form: initialForm,
    tableData: initialTableData,
    tableDataFeedbackReport: initialTableData,
  },
  reducers: {
    setTableData: (state, action) => {
      state.tableData = action.payload
    },
    setTableDataFeedbackReport: (state, action) => {
      state.tableDataFeedbackReport = action.payload
    },
    setForm: (state, action) => {
      state.form = action.payload
    },
    resetForm: (state) => {
      state.form = initialForm
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFeedbackApi.pending, (state) => {
        state.loading = true
      })
      .addCase(getFeedbackApi.fulfilled, (state, action) => {
        state.loading = false
        state.feedbackList = action.payload.data.feedBacks
        state.tableData.total = action.payload.data.totalData
      })
      .addCase(getFeedbackApi.rejected, (state) => {
        state.loading = false
        state.feedbackList = []
      })

    builder
      .addCase(getFeedbackReportApi.pending, (state) => {
        state.loadingReport = true
      })
      .addCase(getFeedbackReportApi.fulfilled, (state, action) => {
        state.loadingReport = false
        state.feedbackReportList = action.payload.data.feedBackReports
        state.tableData.total = action.payload.data.totalData
      })
      .addCase(getFeedbackReportApi.rejected, (state) => {
        state.loadingReport = false
        state.feedbackReportList = []
      })
  },
})

export const { setTableData, setTableDataFeedbackReport, setForm, resetForm } = dataSlice.actions

export default dataSlice.reducer
