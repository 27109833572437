import { createSlice } from '@reduxjs/toolkit'

const initialForm = { id: '', month: new Date().getMonth() + 1, year: new Date().getFullYear() }

const stateSlice = createSlice({
  name: 'rfidStatus/state',
  initialState: {
    dialog: false,
    form: initialForm,
  },

  reducers: {
    setDialogStatus: (state, action) => {
      state.dialog = action.payload.dialog
      state.form = action.payload.form
    },
  },
})

export const { setDialogStatus } = stateSlice.actions

export default stateSlice.reducer
