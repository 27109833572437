import { CHARGING_MANAGEMENT } from 'constants/api.constant'
import ApiService from './ApiService'

/**
 * API Call to get the feedback config list
 */
export const fetchFeedbackConfigApi = async (params) => {
  return ApiService.fetchData({
    url: `${CHARGING_MANAGEMENT}/feedback-configs?search=${params.tableSearch}`,
    method: 'get',
  })
}

export const createFeedbackConfigApi = async (data) => {
  console.log('createFeedbackConfigApi', data)
  return ApiService.fetchData({
    url: `${CHARGING_MANAGEMENT}/feedback-configs`,
    method: 'post',
    data,
  })
}

export const updateFeedbackConfigApi = async (data, id) => {
  return ApiService.fetchData({
    url: `${CHARGING_MANAGEMENT}/feedback-configs/${id}`,
    method: 'put',
    data,
  })
}

export const deleteFeedbackConfigApi = async (id) => {
  return ApiService.fetchData({
    url: `${CHARGING_MANAGEMENT}/feedback-configs/${id}`,
    method: 'delete',
  })
}
