import { createSlice } from '@reduxjs/toolkit'

const stateSlice = createSlice({
  name: 'rfid/state',
  initialState: {
    deleteConfirmation: false,
    isShowDialog: false,
    titleDialog: '',
    actionId: '',

    topUpForm: {
      isShow: false,
      id: '',
      data: {},
    },
  },

  reducers: {
    setTitleDialog: (state, action) => {
      state.titleDialog = action.payload
    },
    setDescriptionDialog: (state, action) => {
      state.descriptionDialog = action.payload
    },
    toggleDeleteConfirmation: (state, action) => {
      state.deleteConfirmation = action.payload
    },
    toggleDialog: (state, action) => {
      state.isShowDialog = action.payload
    },
    setActionId: (state, action) => {
      state.actionId = action.payload
    },

    // Top Up Form
    toggleTopUpForm: (state, action) => {
      state.topUpForm.isShow = action.payload
    },
    setTopUpFormId: (state, action) => {
      state.topUpForm.id = action.payload
    },
    setTopUpFormData: (state, action) => {
      state.topUpForm.data = action.payload
    },
    resetTopUpForm: (state) => {
      state.topUpForm = {
        isShow: false,
        id: '',
        data: {},
      }
    },
  },
})

export const {
  toggleDeleteConfirmation,
  toggleDialog,
  setTitleDialog,
  setDescriptionDialog,
  setActionId,
} = stateSlice.actions

export const actions = stateSlice.actions

export default stateSlice.reducer
