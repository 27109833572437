import ApiService from './ApiService'
import { USER_MANAGEMENT } from 'constants/api.constant'

// USERS SERVICE
export const fetchUserApi = async (param) =>
  ApiService.fetchData({
    url:
      USER_MANAGEMENT +
      '/users?page=' +
      param.pageIndex +
      '&size=' +
      param.pageSize +
      '&searchByName=' +
      param.tableSearch +
      '&searchByRole=' +
      param.role,
    method: 'get',
  })

export const fetchUserById = async (id) =>
  ApiService.fetchData({
    url: USER_MANAGEMENT + '/users/' + id,
    method: 'get',
  })

export const createUserApi = async (data) =>
  ApiService.fetchData({
    url: USER_MANAGEMENT + '/users',
    method: 'post',
    data,
  })

export const reactiveUserApi = async (data) =>
  ApiService.fetchData({
    url: USER_MANAGEMENT + '/users/reactivate',
    method: 'post',
    data,
  })

export const updateUserApi = async (data, id) =>
  ApiService.fetchData({
    url: USER_MANAGEMENT + '/users/' + id,
    method: 'patch',
    data,
  })

export const deleteUserApi = async (id) =>
  ApiService.fetchData({
    url: USER_MANAGEMENT + '/users/' + id,
    method: 'delete',
  })

// PROFILE SERVICE
export const fetchProfilesApi = async () =>
  ApiService.fetchData({
    url: USER_MANAGEMENT + '/profiles',
    method: 'get',
  })

export const fetchProfileApi = async (id = '') =>
  ApiService.fetchData({
    url: USER_MANAGEMENT + '/profiles/' + id,
    method: 'get',
  })

export const fetchUserProfileApi = async (id) =>
  ApiService.fetchData({
    url: USER_MANAGEMENT + '/profiles/by-user-id/' + id,
    method: 'get',
  })

export const createProfileApi = async (data) =>
  ApiService.fetchData({
    url: USER_MANAGEMENT + '/profiles',
    method: 'post',
    data,
  })

export const updateProfileApi = (data, id) =>
  ApiService.fetchData({
    url: USER_MANAGEMENT + '/profiles/' + id,
    method: 'patch',
    data,
  })

export const deleteProfileApi = (id) =>
  ApiService.fetchData({
    url: USER_MANAGEMENT + '/profiles/' + id,
  })

// MITRAS SERVICE
export const fetchMitraApi = async (param) =>
  ApiService.fetchData({
    url:
      USER_MANAGEMENT +
      '/mitras?page=' +
      param.pageIndex +
      '&size=' +
      param.pageSize +
      '&search=' +
      param.tableSearch,
    method: 'get',
  })

export const fetchMitraByIdApi = async (id) =>
  ApiService.fetchData({
    url: USER_MANAGEMENT + '/mitras/' + id,
    method: 'get',
  })

export const fetchUserMitraApi = async (id) =>
  ApiService.fetchData({
    url: USER_MANAGEMENT + '/mitras/by-user-id/' + id,
    method: 'get',
  })

export const createMitraApi = async (data) =>
  ApiService.fetchData({
    url: USER_MANAGEMENT + '/mitras',
    method: 'post',
    data,
  })

export const updateMitraApi = async (data, id) =>
  ApiService.fetchData({
    url: USER_MANAGEMENT + '/mitras/' + id,
    method: 'patch',
    data,
  })

export const deleteMitraApi = async (id) =>
  ApiService.fetchData({
    url: USER_MANAGEMENT + '/mitras/' + id,
    method: 'delete',
  })

export const fetchTokenOcppApi = async () =>
  ApiService.fetchData({
    url: USER_MANAGEMENT + '/auth/ocpp-token-list',
    method: 'get',
  })

export const generateTokenOcppApi = async () =>
  ApiService.fetchData({
    url: USER_MANAGEMENT + '/auth/generate-ocpp-token',
    method: 'post',
  })
