import ApiService from './ApiService'
import { USER_MANAGEMENT } from 'constants/api.constant'

export async function apiSignIn(data) {
  return ApiService.fetchData({
    url: USER_MANAGEMENT + '/auth/login',
    method: 'post',
    data,
  })
}

export async function apiSignOut(data) {
  return ApiService.fetchData({
    url: USER_MANAGEMENT + '/auth/logout',
    method: 'post',
    data,
  })
}

export async function apiForgotPassword(data) {
  return ApiService.fetchData({
    url: USER_MANAGEMENT + '/auth/forgot-password',
    method: 'post',
    data,
  })
}

export async function apiResetPassword(data, token) {
  return ApiService.fetchData({
    url: USER_MANAGEMENT + '/auth/reset-password?token=' + token,
    method: 'post',
    data,
  })
}
