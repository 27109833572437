import ApiService from './ApiService'
import { CHARGING_MANAGEMENT } from 'constants/api.constant'

export const fetchFeedbackReportApi = async (param) =>
  ApiService.fetchData({
    url:
      CHARGING_MANAGEMENT +
      '/feedBackReports/?page=' +
      param.pageIndex +
      '&size=' +
      param.pageSize +
      '&search=' +
      param.tableSearch,
    method: 'get',
  })

export const fetchFeedbackReportByFeedbackApi = async (id) =>
  ApiService.fetchData({
    url: CHARGING_MANAGEMENT + '/feedBackReports/by-feedBack-id/' + id,
    method: 'get',
  })

export const createFeedbackReportApi = async (data) =>
  ApiService.fetchData({
    url: CHARGING_MANAGEMENT + '/feedBackReports',
    method: 'post',
    data,
  })

export const updateFeedbackReportApi = async (data, id) =>
  ApiService.fetchData({
    url: CHARGING_MANAGEMENT + '/feedBackReports/' + id,
    method: 'patch',
    data,
  })

export const deleteFeedbackReportApi = async (id) =>
  ApiService.fetchData({
    url: CHARGING_MANAGEMENT + '/feedBackReports/' + id,
    method: 'delete',
  })
