import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchWalletAmountApi, fetchWalletHistoryApi } from 'services/TransactionService'

const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
}

const initialData = {
  loading: false,
  data: [],
}

export const getWalletById = createAsyncThunk('wallet/getWalletById', async (id) => {
  const response = await fetchWalletAmountApi(id)
  return response.data
})

export const getWalletHistory = createAsyncThunk('wallet/getWalletHistory', async (param) => {
  const response = await fetchWalletHistoryApi(param)
  return response.data
})

const dataSlice = createSlice({
  name: 'wallet/data',
  initialState: {
    walletAmount: initialData,
    walletHistory: initialData,
    tableData: initialTableData,
  },
  reducers: {
    setTableData: (state, action) => {
      state.tableData = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWalletById.pending, (state) => {
        state.walletAmount.loading = true
      })
      .addCase(getWalletById.fulfilled, (state, action) => {
        state.walletAmount.data = action.payload.data
        state.walletAmount.loading = false
      })
      .addCase(getWalletById.rejected, (state) => {
        state.walletAmount.loading = false
        state.walletAmount.data = ''
      })

    builder
      .addCase(getWalletHistory.pending, (state) => {
        state.walletHistory.loading = true
      })
      .addCase(getWalletHistory.fulfilled, (state, action) => {
        state.walletHistory.data = action.payload.data.walletHistorys
        state.tableData.total = action.payload.data.totalData
        state.walletHistory.loading = false
      })
      .addCase(getWalletHistory.rejected, (state) => {
        state.walletHistory.loading = false
        state.walletHistory.data = []
      })
  },
})

export const { setTableData, setStartDate, setEndDate, detailTransaction } = dataSlice.actions

export default dataSlice.reducer
