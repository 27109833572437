import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchFaqsApi, fetchFaqsByIdApi } from 'services/FaqService'

const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  tableSearch: '',
}

const initialForm = {
  id: '',
  question: '',
  answer: '',
  sortNum: '',
  showInUser: 0,
  showInCustomer: 0,
}

export const getFaqsApi = createAsyncThunk('faq/getFaqsApi', async (param) => {
  const response = await fetchFaqsApi(param)
  return response.data
})

export const getFaqByIdApi = createAsyncThunk('faq/getFaqByIdApi', async (id) => {
  const response = await fetchFaqsByIdApi(id)
  return response.data
})

const dataSlice = createSlice({
  name: 'faq/data',
  initialState: {
    loading: true,
    faqList: [],
    form: initialForm,
    tableData: initialTableData,
  },
  reducers: {
    setTableData: (state, action) => {
      state.tableData = action.payload
    },
    setTableSearch: (state, action) => {
      state.tableSearch = action.payload
    },
    setForm: (state, action) => {
      state.form = action.payload
    },
    resetForm: (state) => {
      state.form = initialForm
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFaqsApi.pending, (state) => {
        state.loading = true
      })
      .addCase(getFaqsApi.fulfilled, (state, action) => {
        state.loading = false
        state.faqList = action.payload.data.faqs
        state.tableData.total = action.payload.data.totalData
      })
      .addCase(getFaqsApi.rejected, (state) => {
        state.loading = false
        state.faqList = []
      })

    builder
      .addCase(getFaqByIdApi.pending, (state) => {
        state.loading = true
      })
      .addCase(getFaqByIdApi.fulfilled, (state, action) => {
        state.loading = false
        state.form = action.payload.data
      })
      .addCase(getFaqByIdApi.rejected, (state) => {
        state.loading = false
        state.form = initialForm
      })
  },
})

export const { setTableData, setTableSearch, setForm, resetForm } = dataSlice.actions

export default dataSlice.reducer
