import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchSplashScreenApi, fetchSplashScreenByIdApi } from 'services/SplashScreenService'

const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  tableSearch: '',
}

const initialForm = {
  id: '',
  name: '',
  imageId: '',
  content: '',
}

export const getSplashScreenApi = createAsyncThunk(
  'splashScreen/getSplashScreenApi',
  async (param) => {
    const response = await fetchSplashScreenApi(param)
    return response.data
  },
)

export const getSplashScreenByIdApi = createAsyncThunk(
  'splash/getSplashScreenByIdApi',
  async (id) => {
    const response = await fetchSplashScreenByIdApi(id)
    return response.data
  },
)

const dataSlice = createSlice({
  name: 'splash/data',
  initialState: {
    loading: true,
    splashList: [],
    form: initialForm,
    tableData: initialTableData,
    tableSearch: '',
  },
  reducers: {
    setTableData: (state, action) => {
      state.tableData = action.payload
    },
    setTableSearch: (state, action) => {
      state.tableSearch = action.payload
    },
    setForm: (state, action) => {
      state.form = action.payload
    },
    resetForm: (state) => {
      state.form = initialForm
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSplashScreenApi.pending, (state) => {
        state.loading = true
      })
      .addCase(getSplashScreenApi.fulfilled, (state, action) => {
        state.splashList = action.payload.data.splashScreens
        state.tableData.total = action.payload.data.totalData
        state.loading = false
      })
      .addCase(getSplashScreenApi.rejected, (state) => {
        state.loading = false
        state.splashList = []
      })

    builder
      .addCase(getSplashScreenByIdApi.pending, (state) => {
        state.loading = true
      })
      .addCase(getSplashScreenByIdApi.fulfilled, (state, action) => {
        state.loading = false
        state.form = action.payload.data
      })
      .addCase(getSplashScreenByIdApi.rejected, (state) => {
        state.loading = false
        state.form = initialForm
      })
  },
})

export const { setTableData, setTableSearch, setForm, resetForm, setLoading } = dataSlice.actions

export default dataSlice.reducer
