import { createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

const stateSlice = createSlice({
  name: 'dashboard/state',
  initialState: {
    yearSelect: new Date().getFullYear(),
    startDate: dayjs().subtract(1, 'month').toDate(),
    endDate: new Date(),
  },
  reducers: {
    setYearSelect: (state, action) => {
      state.yearSelect = action.payload
    },
    setStartDate: (state, action) => {
      state.startDate = action.payload
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload
    },
  },
})

export const { setYearSelect, setStartDate, setEndDate } = stateSlice.actions

export default stateSlice.reducer
