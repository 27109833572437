import ApiService from './ApiService'
import { CONTENT_MANAGEMENT } from 'constants/api.constant'

export const fetchPromoApi = async (param) => {
  let url = CONTENT_MANAGEMENT + '/promotions'

  if (param != undefined && param.id !== undefined) {
    url += '/' + param.id
  }

  return ApiService.fetchData({
    url,
    method: 'get',
    params: {
      search: param !== undefined && param.query !== undefined ? param.query : '',
    },
  })
}

export const createPromoApi = async (data) =>
  ApiService.fetchData({
    url: CONTENT_MANAGEMENT + '/promotions',
    method: 'post',
    data,
  })

export const updatePromoApi = async (data, id) =>
  ApiService.fetchData({
    url: CONTENT_MANAGEMENT + '/promotions/' + id,
    method: 'patch',
    data,
  })

export const deletePromoApi = async (id) =>
  ApiService.fetchData({
    url: CONTENT_MANAGEMENT + '/promotions/' + id,
    method: 'delete',
  })
